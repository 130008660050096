import React from "react"
import { NeurondCard } from "../Cards"
import Row from "react-bootstrap/Row"
import "./BenefitsCard.scss"

const cnb_section_data = [
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/Benefits/Vector/TechnicalTraining.svg`,
    title: "Technical training",
    description: `Technical skills... cutting-edge technologies.`,
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/Benefits/Vector/EnglishTraining.svg`,
    title: "English training",
    description: `Because we work... and spoken English.`,
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/Benefits/Vector/ProcessTraining.svg`,
    title: "Process training",
    description: `Neurond AI’s success... interpersonal and soft skills.`,
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/branch/Benefits/Vector/KnowledgeExchangeInitiative.svg`,
    title: "Knowledge Exchange Initiative",
    description: `Every month we... rest of the company.`,
  },
]

const BenefitsCard = () => (
  <section className={`benefits-card margin-bot wrapper`}>
    <Row className="benefits-card__row">
      {cnb_section_data.map((item, index) => {
        return (
          <div className="benefits-card__row__col-md-6 shadow-on" key={index}>
            <NeurondCard
              title={item.title}
              description={item.description}
              imgLink={item.imgLink}
              isShowBottom={false}
            />
          </div>
        )
      })}
    </Row>
  </section>
)

export default BenefitsCard
